<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      v-model="params"
      @search="search"
      @reset="reset"
      v-if="searchFields.length"
      show-search
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
        <template #typeId="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(deposit)="data">
            <span class="text-wala">
              {{
                data.item.trxType === "cr"
                  ? data.item.amount
                  : 0 | currency(data.item.ccy)
              }}
            </span>
          </template>
          <template #cell(withdraw)="data">
            <span class="text-meron">
              {{
                data.item.trxType === "dr"
                  ? data.item.amount
                  : 0 | currency(data.item.ccy)
              }}
            </span>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th colspan="3"></b-th>
              <b-th class="text-right">
                <h5 class="text-wala font-weight-bold">
                  {{ totalReport.totalDeposit | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5 class="text-meron font-weight-bold">
                  {{ totalReport.totalWithdrawal | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BTr,
  BTh,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const TransactionRepository = Repository.get("transaction");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    typeId: function () {
      this.setSearchFields(this.typeId);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.userId ||
        !!this.$route.query.startDate ||
        !!this.$route.query.endDate
      );
    },
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      return this.userData?.id;
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        userId: Number(this.$route.query.userId) || null,
        typeId: Number(this.$route.query.typeId) || null,
        startDate: this.$route.query.startDate || moment().format("YYYY-MM-DD"),
        endDate: this.$route.query.endDate || moment().format("YYYY-MM-DD"),
        period: this.$route.query.period || "today",
        search: this.$route.query.search || null,
      },
      avatarText,
      searchFields: [],
      totalReport: {},
    };
  },
  mounted() {
    this.getData(this.params.page);
    if (this.$store.state.profile.typeId) {
      this.setSearchFields(this.$store.state.profile.typeId);
    }
  },
  methods: {
    setSearchFields(userTypeId) {
      let typeSearch = {
        key: "typeId",
        label: "field.type",
        type: "radio",
        cols: 6,
      };
      if (userTypeId === 1 || userTypeId === 8) {
        typeSearch["options"] = [
          {
            value: 2,
            text: "breadcrumb.company",
          },
          {
            value: 3,
            text: "breadcrumb.senior",
          },
          {
            value: 4,
            text: "breadcrumb.master",
          },
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 2) {
        typeSearch["options"] = [
          {
            value: 3,
            text: "breadcrumb.senior",
          },
          {
            value: 4,
            text: "breadcrumb.master",
          },
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 3) {
        typeSearch["options"] = [
          {
            value: 4,
            text: "breadcrumb.master",
          },
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 4) {
        typeSearch["options"] = [
          {
            value: 5,
            text: "breadcrumb.agent",
          },
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      } else if (userTypeId === 5) {
        typeSearch["options"] = [
          {
            value: 6,
            text: "breadcrumb.player",
          },
        ];
      }
      this.searchFields = [...searchInputs, typeSearch];
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.params.username = "";
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      TransactionRepository.index({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.totalReport = {};
          this.loading = false;
          this.getTotal();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      TransactionRepository.total({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data;
          this.totalReport = {
            ...data,
          };
        })
        .catch(() => {});
    },
  },
  setup() {
    const fields = [...tableFields];
    return {
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
