export default [
  {
    key: 'userId',
    label: 'field.player',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'player',
    selectionKey: 'id',
    selectionLabel: 'username',
    clearable: true,
    cols: 2,

  },
  {
    key: 'startDate',
    label: 'field.fromDate',
    type: 'date',
    cols: 2,
  },
  {
    key: 'endDate',
    label: 'field.toDate',
    type: 'date',
    cols: 2,
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
    ],
    cols: 6,
  },
]
